<template>
    <div>
        <van-nav-bar
                placeholder
                fixed
                left-arrow
                :border='false'
                title="提现进度"
                z-index="99"
                class="header"
                @click-left="$router.go(-1)">
            <template #left>
                <van-icon name="arrow-left" size="25" color="#0A1C33"/>
            </template>
        </van-nav-bar>
        <div class="progress-list">
            <div class="progress">
                <p class="progress-icon">
                    <van-icon name="checked" color="#4D77FD" size="25"/>
                </p>
                <div class="progress-left">
                    <p class="progress-title">10.00元提现申请已提交</p>
                    <p class="progress-time">2020 5-12 9:00</p>
                </div>
            </div>
            <div class="progress">
                <p class="progress-icon">
                    <van-icon name="clock" color="#bfc2cc" size="25"/>
                </p>
                <div class="progress-left">
                    <p class="progress-title">10.00元提现申请已提交</p>
                    <p class="progress-time">2020 5-12 9:00</p>
                </div>
            </div>
            <div class="progress">
                <p class="progress-icon">
                    <van-icon name="clock" color="#bfc2cc" size="25"/>
                </p>
                <div class="progress-left">
                    <p class="progress-title">10.00元提现申请已提交</p>
                    <p class="progress-time">2020 5-12 9:00</p>
                </div>
            </div>
        </div>
        <p class="button">
            我知道了
        </p>
        <p class="text">
            <img src="../../assets/images/progress_remind.png" alt="" class="progress_remind">
            您可以在提现明细里面查看提现详情</p>
    </div>
</template>

<script>
    export default {
        name: "Progress"
    }
</script>

<style scoped>
    .progress_remind{
        width: 26px;
        height: 23px;
        margin-right: 10px;
    }
    .text{
        color: #BFC2CC;
        font-size: 24px;
        margin-top: 48px;
        display: flex;
        align-items: center;
        padding: 0 40px;
    }
    .button{
        width:670px;
        height:88px;
        border:1px solid rgba(77,119,253,1);
        border-radius:44px;
        font-size:28px;
        font-weight:400;
        color:rgba(77,119,253,1);
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 40px 0;
    }
    .progress-icon{
        position: absolute;
        top: 0;
        left: -25px;
        background: #fff;
    }
    .progress-list{
        margin: 40px 60px 120px;
        border-left: 1px solid #BFC2CC;
    }
    .progress:last-of-type .progress-time{
        position: absolute;
    }
    .progress-left{
        margin-left: 30px;
        padding: 0 15px;
    }
    .progress-time {
        color: #BFC2CC;
        font-size: 24px;
        margin-top: 27px;
    }

    .progress-title {
        color: #606266;
        font-size: 26px;
    }

    .progress {
        display: flex;
        align-items: center;
        margin-bottom: 58px;
        position: relative;
    }
</style>